/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, MITSUI_HC){
	const a = 'is-active';
	const v = 'is-visible';

	// 「もっと見る」最新年号へ飛ばす
	const newsMoreBtn = () => {
		let newsEarliestYtxt = $('.js-news-MoreBtn').eq(0).find('.rt_earliest_year').text();
		let newsEarliestY = newsEarliestYtxt.slice(0, -2);
		let newsEarliestY_month =Number(newsEarliestYtxt.slice(-2));
		if(newsEarliestY_month < 4) {
			newsEarliestY -= 1;
		}
		let newsNextY = Number(newsEarliestY)+ 1;
		$('.js-news-MoreBtn').find('.mod-button-a').attr('href', '/news/?p_year='+ newsEarliestY +'04_'+ newsNextY +'04');

		let pickupEarliestYtxt = $('.js-pickup-MoreBtn').find('.rt_earliest_year').text();
		let pickupEarliestY = pickupEarliestYtxt.slice(0, -2);
		let pickupEarliestY_month =Number(pickupEarliestYtxt.slice(-2));
		if(pickupEarliestY_month < 4) {
			pickupEarliestY -= 1;
		}
		let pickupNextY = Number(pickupEarliestY)+ 1;
		$('.js-pickup-MoreBtn').find('.mod-button-a').attr('href', '/pickup/?p_year='+ pickupEarliestY +'04_'+ pickupNextY +'04');
	};

	// <PICK UP>ラベル
	const pickupLabel = () => {
		$('.js-pickup-item').each((idx, ele) => {
			let labelLen = $(ele).find('.__label').length;
			let labelText = $(ele).find('.__label').eq(0).find('.rt_cf_n_related_facilities_name').text() +' 他 '+ Number(labelLen-1) +'施設';
			if(labelLen > 1) {
				// 2件以上
				$(ele).find('.__img').after('<span class="__label">'+ labelText);
			} else {
				// 1件のみ
				$(ele).find('.js-pickup-labels').removeClass('di_none');
			}
		});
	};

	// <PICK UP>説明テキスト
	const pickupText = () => {
		let $setElm = $('.js-pickup-text');
		let cutFigure = '35';
		let afterTxt = '…';

		$setElm.each((idx, ele) => {
			let textLength = $(ele).text().length;
			let textTrim = $(ele).text().substr(0, (cutFigure));

			if(cutFigure < textLength) {
				$(ele).html(textTrim + afterTxt).css({ visibility:'visible' });
			} else if(cutFigure >= textLength) {
				$(ele).css({ visibility:'visible' });
			}
		});
	};


	// ====================================================================
	newsMoreBtn();
	pickupLabel();
	pickupText();

})(window.jQuery, window.MITSUI_HC);
